<app-loading-spinner
    *ngIf='isLoading'
    [overlay]='true'></app-loading-spinner>

<div class='card vus-card stretch'>
    <div class='card-body'>
        <span class='start-title' *ngIf='testIsRunning'>
            Testlauf abbrechen
        </span>
        <span class='start-title' *ngIf='!testIsRunning'>
           Neuen Testlauf starten
        </span>
        <p class='subtitle'>
            Ein Testlauf kann etwa zwei Stunden in Anspruch nehmen.
        </p>
        <button *ngIf='testIsRunning' (click)="openConfirmationModal()" class='btn btn-primary mt justify'>
            Abbrechen
        </button>
        <button *ngIf='!testIsRunning' (click)='startTest()' class='btn btn-primary mt justify'>
            Testlauf starten
        </button>
    </div>
</div>
