import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { TestautomationTestSuccess } from '@shared/models/testautomation/TestautomationTest';
import { TestautomationTestDetail } from '@shared/models/testautomation/TestautomationTestDetail';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-test-detail',
  templateUrl: './test-detail.component.html',
  styleUrl: './test-detail.component.scss'
})
export class TestDetailComponent implements OnInit{
    @ViewChild('dataTable', {static: true}) table: DatatableComponent;

    showOnlyFailed: boolean = false;
    @Input() test: TestautomationTestSuccess;
    @Input() details: TestautomationTestDetail[];

    filteredDetails: TestautomationTestDetail[];
    dataTableLimit: number = 10;

    constructor(
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {
        if (this.test == null) {
            let date: Date = new Date(2024, 0, 1, 0, 0, 0, 0);
            this.test = new TestautomationTestSuccess(date.toString(), 0, 0, 0, 'UNKNOWN');
        }
        this.filteredDetails = this.details;
        this.showOnlyFailed = false;
    }

    updateFilterDetails(): void {
        this.filteredDetails = this.showOnlyFailed
            ? this.details.filter(detail => detail.status == 'FAILED')
            : this.details;
    }

    toggleFilter(): void {
        this.showOnlyFailed = !this.showOnlyFailed;
        this.updateFilterDetails();
    }
    setLimit(event) {
        this.dataTableLimit = event;

        setTimeout(() => {
            this.table.limit = this.dataTableLimit;
            this.table.recalculate();
        });
    }
}
