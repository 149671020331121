import { Component, Input, OnInit } from '@angular/core';
import { TestautomationTestSuccess } from '@shared/models/testautomation/TestautomationTest';

@Component({
  selector: 'app-test-result',
  templateUrl: './test-result.component.html',
  styleUrl: './test-result.component.scss'
})
export class TestResultComponent implements OnInit{
    @Input() recentTestRun: TestautomationTestSuccess;

    constructor() { }

    ngOnInit(): void {
        if (this.recentTestRun == null) {
            let date: Date = new Date(2024, 0, 1, 0, 0, 0, 0);
            this.recentTestRun = new TestautomationTestSuccess(date.toString(), 0, 0, 0, 'UNKNOWN');
        }
    }
}
