import { Component, Input, OnInit } from '@angular/core';
import { TestautomationTest, TestautomationTestSuccess } from '@shared/models/testautomation/TestautomationTest';
import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TestautomationService } from '@shared/services/testautomation/testautomation.service';
import { TestautomationTestDetail } from '@shared/models/testautomation/TestautomationTestDetail';
import { TestDetailComponent } from '../test-detail/test-detail.component';
import { takeUntil } from 'rxjs/operators';
import { SwitchService } from '@shared/services/switch/switch.service';
import { ApiService } from '@shared/services/api/api.service';
import { HttpResponse } from '@angular/common/http';
import { DownloadService } from '@shared/services/download/download.service';

@Component({
  selector: 'app-test-history',
  templateUrl: './test-history.component.html',
  styleUrl: './test-history.component.scss'
})
export class TestHistoryComponent implements OnInit{

    @Input() testRuns: TestautomationTest[] | TestautomationTestSuccess[];

    private ROOT_URL: string;
    private switchSetting: string;
    private notifier = new Subject();

    date: Date = new Date(2024, 0, 1, 0, 0, 0, 0);
    testautomationTest1 = new TestautomationTestSuccess(this.date.toString(), 0, 0, 0, 'UNKNOWN');
    sortConfig = [{ prop: 'timeStamp', dir: 'desc' }];


    constructor(
        private modalService: NgbModal,
        private testautomationService: TestautomationService,
        private switchService: SwitchService,
        private apiService: ApiService,
        private downloadService : DownloadService
    ) { }

    ngOnInit(): void {
        console.log(this.testRuns)
        let mocktestruns: TestautomationTestSuccess[] = [];
        mocktestruns.push(this.testautomationTest1)
        if (this.testRuns == null) {
            this.testRuns = mocktestruns;
        }
        console.log(this.testRuns)
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'BASE',
                    this.switchSetting
                );
            });
    }

    public formatTime(row, ms: number): string {
        if(row.status === "cancelled") {
            return '-';
        }
        else if(row.status === "success") {
            const hours = Math.floor(ms / 3600000);
            const minutes = Math.floor((ms % 3600000) / 60000);
            const seconds = Math.floor((ms % 60000) / 1000);

            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');

            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        }
        else if(row.status === "running") {
            const now: any = new Date().getTime();
            const ms = now - new Date(row.timeStamp).getTime();;
            const hours = Math.floor(ms / 3600000);
            const minutes = Math.floor((ms % 3600000) / 60000);
            const seconds = Math.floor((ms % 60000) / 1000);

            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');

            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        }
    }

    detail(test: TestautomationTestSuccess): void {
        this.getDetailsByTestTimestamp(
            test
        ).subscribe((details) =>
            this.openDetailModal(test, details)
        );
    }

    exportTestRecord(test: TestautomationTestSuccess) : void{
        this.testautomationService.getTestRecordData(this.ROOT_URL, test.username + '_' + test.timeStamp)
        .pipe(takeUntil(this.notifier))
        .subscribe((response: HttpResponse<Blob>) =>
            this.downloadService.downloadFileWithName(response.body,test.username + '_' + test.timeStamp + ' TestReport')
        );
    }

    private openDetailModal(test: TestautomationTestSuccess, testDetails: TestautomationTestDetail[]) {
        const modalRef = this.modalService.open(TestDetailComponent, {
            size: 'lg',
            windowClass: 'modalVeryBig',
            backdrop: 'static',
            keyboard: false
        });
        modalRef.componentInstance.test = test;
        modalRef.componentInstance.details = testDetails;
    }

    private getDetailsByTestTimestamp(test: TestautomationTestSuccess): Observable<TestautomationTestDetail[]> {
        return this.testautomationService.getTestDetails(this.ROOT_URL, test.username + '_' + this.reformatTimestamp(test.timeStamp)).pipe(takeUntil(this.notifier));
    }

    private reformatTimestamp (timeStamp: string): string {
        return timeStamp.replace(/:/g, ".");
    }
    
    isStatusCompleted(row) {
        return row.status === "success" ? false : true;
    }
}
