import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import { TestautomationService } from '@shared/services/testautomation/testautomation.service';
import { takeUntil } from 'rxjs/operators';
import { SwitchService } from '@shared/services/switch/switch.service';
import { ApiService } from '@shared/services/api/api.service';
import { SecurityService } from '@shared/services/security/security.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningComponent } from '@shared/components/warning/warning.component';

@Component({
  selector: 'app-test-start',
  templateUrl: './test-start.component.html',
  styleUrl: './test-start.component.scss'
})
export class TestStartComponent implements OnInit{
    @Input() testIsRunning: boolean;
    @Input() activeTestId: number;
    @Output() testStartedEvent = new EventEmitter<boolean>();
    private ROOT_URL: string;
    private switchSetting: string;
    private notifier = new Subject();
    isLoading = false;
    loadingSubtitle: string;

    constructor(
        private testautomationService: TestautomationService,
        private switchService: SwitchService,
        private apiService: ApiService,
        private securityService: SecurityService,
        private modalService: NgbModal,
    ) { }

    ngOnInit(): void {
        this.loadingSubtitle = this.testIsRunning ? 
        'Ein Testlauf wird gestartet. Bitte einen Moment Geduld ... ':
        'Der Testlauf wird abgebrochen. Bitte einen Moment Geduld ... ';
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'BASE',
                    this.switchSetting
                );
            });
    }

    startTest(){
        this.isLoading = true;
        const username = this.securityService.user.upn;
        this.testautomationService.startTestRun(this.ROOT_URL).subscribe(
            response =>{
                console.log(
                    'Testlauf erfolgreich gestartet!', response
                );
                this.getActiveTestRunIdWithTimeout().subscribe(
                    async (response: number) => {
                        console.log(response);
                        this.isLoading = false;
                        await this.testautomationService.postActiveTestRunId(this.ROOT_URL, response, username).subscribe((resp) => {
                            this.testStartedEvent.emit(true);
                            this.checkUntilTestStopped(response, username).subscribe(
                                (response: number) => {
                                    this.testStartedEvent.emit(false);
                                },
                                (error: any) => {
                                    console.error(error);
                                }
                            );
                        });
                        
                    },
                    (error: any) => {
                        console.error(error);
                    }
                );
            },
            error => {
                console.error(
                    'Testlauf konnte nicht gestartet werden!', error
                );
            }
        );
    }

    checkUntilTestStopped(response, username): Observable<number> {
        return new Observable<number>((observer: Observer<number>) => {
            const makeBackendCall = () => {
                this.testautomationService.postActiveTestRunId(this.ROOT_URL, response, username).subscribe(
                    (response: number) => {
                        if (response !== null) {
                            setTimeout(() => {
                                makeBackendCall();
                            }, 3000);
                        } else {
                            observer.next(response);
                            observer.complete();
                        }
                    },
                    (error: any) => {
                      console.error(error);
                    }
                );
            };
            makeBackendCall();
        });
    }

    getActiveTestRunIdWithTimeout(){
        return new Observable<number>((observer: Observer<number>) => {
            const makeBackendCall = () => {
                this.testautomationService.getActiveTestRunId(this.ROOT_URL).subscribe(
                    (response: number) => {
                        if (response !== null) {
                            observer.next(response);
                            observer.complete();
                        } else {
                            setTimeout(() => {
                                makeBackendCall();
                            }, 3000);
                        }
                    },
                    (error: any) => {
                        observer.error(error);
                    }
                );
            };
            makeBackendCall();
        });
    }

    openConfirmationModal() {
        const modalRef = this.modalService.open(WarningComponent, {
            size: 'lg',
            windowClass: 'confirmation-modal',
            backdrop: 'static',
            keyboard: false,
        });
        modalRef.componentInstance.message ='' +
            '<p>Sie sind im Begriff, den laufenden Test abzubrechen. ' +
            'In diesem Fall gehen alle Ergebnisse dieses Testlaufs verloren und es wird nichts gespeichert. ' +
            'Sind Sie sicher, dass Sie den Test abbrechen möchten?</p>'
        modalRef.componentInstance.closingOption = false;
        modalRef.result.then(
            result => {
                this.isLoading = true;
                this.getActiveTestRunId();
                this.testautomationService.cancelActiveTest(this.ROOT_URL,this.activeTestId,).subscribe(
                    response =>{
                        console.log(
                            'Test erfolgreich abgebrochen!', response
                        );
                        this.checkUntilTestStoppedGet().subscribe(
                            (response: number) => {
                                console.log(
                                    'Test erfolgreich abgebrochen!'
                                );
                                this.isLoading = false;
                                this.testStartedEvent.emit(false);
                            },
                            (error: any) => {
                                console.error(error);
                            },
                            () => {
                                console.log('Observable abgeschlossen');
                            }
                        );
                    },
                    error => {
                        console.error(
                            'Test konnte nicht abgebrochen werden!', error
                        );
                    }
                );
        });
    }

    private getActiveTestRunId(){
        this.isLoading = true;
        this.testautomationService.getActiveTestRunId(this.ROOT_URL)
            .pipe(
                takeUntil(this.notifier)
            )
            .subscribe(
                (resp : number) =>{
                    this.activeTestId = resp;
                    this.isLoading = false;
                },
                (error: any) => {
                    console.error(error);
                    this.isLoading = false;
                }
            )
    }

    checkUntilTestStoppedGet(): Observable<number> {
        return new Observable<number>((observer: Observer<number>) => {
            const makeBackendCall = () => {
                this.testautomationService.getActiveTestRunId(this.ROOT_URL).subscribe(
                    (response: number) => {
                        if (response !== null) {
                            setTimeout(() => {
                                makeBackendCall();
                            }, 3000);
                        } else {
                            observer.next(response);
                            observer.complete();
                        }
                    },
                    (error: any) => {
                        observer.error(error);
                    }
                );
            };
            makeBackendCall();
        });
    }
}
