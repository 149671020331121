<div class="vus-card-body">
    <div class="vus-container">
        <div class='vus-title title'>
        <span>Testlaufhistorie</span>
        </div>
        <ngx-datatable
            #dataTable
            class="material"
            [columnMode]="'flex'"
            [headerHeight]="56"
            rowHeight="auto"
            [rows]="testRuns"
            [sorts]='sortConfig'>

            <ngx-datatable-column
                name="Teststartzeitpunkt"
                prop="timeStamp"
                [flexGrow]="3"
                [resizeable]="false"
            >

                <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                >
                    <span *ngIf="value"> {{ value | date: "dd.MM.yy, HH:mm:ss" }}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
                name="Testdauer"
                prop="duration"
                [flexGrow]="3"
                [resizeable]="false"
            >

                <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                >
                    <span>{{ formatTime(row, value) }}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
                name="Testfälle bestanden"
                prop="successfulTestCases"
                [flexGrow]="3"
                [resizeable]="false"
            >
                <div class="status-box">
                    <ng-template
                        let-value="value"
                        let-row="row"
                        ngx-datatable-cell-template
                    >
            <span *ngIf="value">
                <span
                    [style.color]="'#008351'"
                >
                    <i class="bi bi-circle-fill"></i>
                </span>
                {{ value }}</span>
                
              <span *ngIf="!value" class="text-center">
                -
             </span>
                    </ng-template>
                </div>
            </ngx-datatable-column>
            <ngx-datatable-column
                name="Testfälle nicht bestanden"
                prop="unsuccessfulTestCases"
                [flexGrow]="3"
                [resizeable]="false"
            >

                <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                >
          <span *ngIf="value">
                <span
                    [style.color]="'#bb1e10'"
                >
                    <i class="bi bi-circle-fill"></i>
                </span>

              {{ value }}</span>
              <span *ngIf="!value" class="text-center">
                -
             </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
                name="User"
                prop="username"
                [flexGrow]="3"
                [resizeable]="false"
            >

                <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                >
                    <span *ngIf="value">{{ value }} </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
                name="Status"
                prop="status"
                [flexGrow]="3"
                [resizeable]="false"
            >

                <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                >
                    <span *ngIf="value === 'success'"><i class="bi bi-check-lg"></i>&nbsp;&nbsp;Vollendet</span>
                    <span *ngIf="value === 'cancelled'"><i class="bi bi-x-lg"></i>&nbsp;&nbsp;Abgesagt</span>
                    <span *ngIf="value === 'running'"><i class="bi bi-hourglass-bottom"></i>&nbsp;&nbsp;Ausstehend</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
                name="Aktionen"
                [flexGrow]="2"
                [sortable]="false"
                [resizeable]="false"
            >
                <ng-template
                    let-rowIndex="rowIndex"
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                >
                    <span 
                        [ngClass]="{'no-drop-cursor': isStatusCompleted(row)}"
                        title="Aktionen"
                    >
                        <span 
                            [ngClass]="{'disabled': isStatusCompleted(row)}"
                            (click)="detail(row)"
                        >
                            <i class="bi bi-eye-fill"></i>
                        </span>
                    </span>
                    &nbsp; &nbsp;
                    <span 
                        [ngClass]="{'no-drop-cursor': isStatusCompleted(row)}"
                        title="Aktionen"
                    >
                        <span 
                            [ngClass]="{'disabled': isStatusCompleted(row)}"
                            (click)="exportTestRecord(row)"
                        >
                            <i class="bi bi-download"></i>
                        </span>
                    </span>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>

