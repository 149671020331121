import { Component, OnDestroy, OnInit } from '@angular/core';
import { TestautomationTest, TestautomationTestSuccess } from '@shared/models/testautomation/TestautomationTest';
import { Subject } from 'rxjs';
import { TestautomationService } from '@shared/services/testautomation/testautomation.service';
import { takeUntil } from 'rxjs/operators';
import { SwitchService } from '@shared/services/switch/switch.service';
import { ApiService } from '@shared/services/api/api.service';
import { SecurityService } from '@shared/services/security/security.service';


@Component({
  selector: 'app-testautomation',
  templateUrl: './testautomation.component.html',
  styleUrl: './testautomation.component.scss'
})
export class TestautomationComponent implements OnInit, OnDestroy{

    private ROOT_URL: string;
    private switchSetting: string;
    private notifier = new Subject();

    isLoading = false;
    testRuns: TestautomationTest[] = [];
    text: string;
    recentTestRun: TestautomationTestSuccess;
    activeTestId: number;
    testIsRunning: boolean;

    constructor(
        private testautomationService: TestautomationService,
        private switchService: SwitchService,
        private apiService: ApiService,
        private securityService: SecurityService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.text = 'Die Testläufe werden geladen. Bitte einen Moment Geduld ...';
        await this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(async data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'BASE',
                    this.switchSetting
                );
                await this.getTests();
            });
    }

    private getTests(): void {
        this.isLoading = true;
        this.testautomationService.getTestRuns(this.ROOT_URL)
            .subscribe(
                (response: TestautomationTest[]) => {
                    let testresponse = [];
                    response['success'].forEach(element => {
                        element.status = "success";
                        testresponse.push(element);
                    });
                    response['cancelled'].forEach(element => {
                        testresponse.push(element);
                    });
                    response['inprogress'].forEach(element => {
                        testresponse.push(element);
                    });
                    console.log(response['inprogress'])
                    if (response['inprogress'].length > 0) {
                        this.checkUntilTestStopped(response['inprogress'][0].id);
                    }
                    this.testRuns = testresponse;
                    this.getRecentTestRun(response["success"]);
                    this.getActiveTestRunId();
                },
                (error: any) => {
                    console.error(error);
                    this.isLoading = false;
                }
            );
    }

    checkUntilTestStopped(id): void {
        console.log(id)
        let counter = 0;
        const username = this.securityService.user.upn;
            const makeBackendCall = () => {
                counter++;
                console.log(counter);
                this.testautomationService.postActiveTestRunId(this.ROOT_URL, id, username).subscribe(
                    (response: number) => {
                        console.log(response)
                        if (response !== null) {
                            setTimeout(() => {
                                makeBackendCall();
                            }, 3000);
                        }
                    },
                    (error: any) => {
                      console.error(error);
                    }
                );
            };
            makeBackendCall();
    }

    private getRecentTestRun(testRuns: TestautomationTestSuccess[]): void {
        if (testRuns.length > 0) {
            this.recentTestRun = testRuns.reduce((prev, current) => {
            let prevDate = new Date(prev.timeStamp);
            let currentDate = new Date(current.timeStamp);
            return currentDate > prevDate ? current : prev;
        }) 
    }
    }

    private getActiveTestRunId(){
        this.isLoading = true;
        this.testautomationService.getActiveTestRunId(this.ROOT_URL)
            .pipe(
                takeUntil(this.notifier)
            )
            .subscribe(
                (resp : number) =>{
                    this.activeTestId = resp;
                    this.checkForActiveTest(resp);
                    this.isLoading = false;
                },
                (error: any) => {
                    console.error(error);
                    this.isLoading = false;
                }
            )
    }

    private checkForActiveTest(testId: number){
        testId === null ? this.testIsRunning = false : this.testIsRunning = true;
    }

    testStartedEvent(event) {
        this.testIsRunning = event;
        this.getTests();
    }

    ngOnDestroy() {
        this.notifier.complete();
    }
}
